import {
  BzAddress,
  BzDateFns,
  bzExpect,
  CompanyGuid,
  getEarliestAvailableDate,
  HtmlString,
  LocalDateString,
  LocalTimeString,
  LocationLookupCandidate,
  OnlineBookingServiceTypeDescriptions,
  OnlineBookingServiceTypeDisplayNames,
  OnlineBookingType,
  phoneUtils,
  ThisShouldNeverHappenError,
} from '@breezy/shared'
import {
  faEnvelope,
  faLocationDot,
  faPhone,
  faUser,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Form, message } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  OnsiteBasicModal,
  OnsiteConfirmModal,
} from '../../adam-components/OnsiteModal/OnsiteModal'
import { OnsitePageSection } from '../../adam-components/OnsitePage/OnsitePageSection'
import { SectionedContent } from '../../adam-components/SectionedCard/SectionedContent'
import { StepProgressIndicators } from '../../adam-components/StepProgressIndicators/StepProgressIndicators'
import { useReactHookFormSubmit } from '../../elements/Forms/useReactHookFormSubmit'
import { HtmlRenderer } from '../../elements/HtmlRenderer/HtmlRenderer'
import Switch from '../../elements/Switch/Switch'
import ThinDivider from '../../elements/ThinDivider'
import { trpc } from '../../hooks/trpc'
import useIsMobile from '../../hooks/useIsMobile'
import {
  useModalState,
  useStrictContext,
  useToggleState,
} from '../../utils/react-utils'
import { InstantBookingIcon } from './InstantBookingIcon'
import { OnlineBookingAvailabilitySubForm } from './OnlineBookingAvailabilitySubForm'
import { OnlineBookingContactSubForm } from './OnlineBookingContactSubForm'
import { OnlineBookingInstantBookingSubForm } from './OnlineBookingInstantBookingSubForm'
import { OnlineBookingRequestDetailsSubForm } from './OnlineBookingRequestDetailsSubForm'
import {
  AvailabilitySubFormData,
  AvailabilitySubFormSchema,
  ContactSubFormData,
  ContactSubFormSchema,
  InstantBookingSubFormData,
  InstantBookingSubFormSchema,
  OnlineBookingCompanyConfigContext,
  OnlineBookingServiceTypeConfigContext,
  RequestDetailsSubFormData,
  RequestDetailsSubFormSchema,
  ServiceTypeConfig,
  useBookableJobTypes,
} from './onlineBookingUtils'

type OnlineBookingFormProps = {
  companyGuid: CompanyGuid
  onSubmit: (bookingType: OnlineBookingType) => void
}

const FORM_STAGES = [
  {
    name: 'Request Details',
  },
  {
    name: 'Availability',
  },
  {
    name: 'Contact Info',
  },
  {
    name: 'Review Request',
  },
] as const

const DEFAULT_STEP = 0

export const OnlineBookingForm = React.memo<OnlineBookingFormProps>(
  ({ companyGuid, onSubmit: externalOnSubmit }) => {
    const isMobile = useIsMobile()
    const [currentStep, setCurrentStep] = useState<number>(DEFAULT_STEP)
    const { tzId } = useStrictContext(OnlineBookingCompanyConfigContext)
    const onlineBookingServiceTypeConfigs = useStrictContext(
      OnlineBookingServiceTypeConfigContext,
    )
    const onlineBookingCompanyConfig = useStrictContext(
      OnlineBookingCompanyConfigContext,
    )

    const companyAppointmentArrivalWindows = useMemo(
      () => onlineBookingCompanyConfig.companyAppointmentArrivalWindows,
      [onlineBookingCompanyConfig],
    )
    const serviceAreaZipCodes = useMemo(
      () => onlineBookingCompanyConfig.serviceAreaZipCodes,
      [onlineBookingCompanyConfig],
    )
    const companyPhoneNumber = useMemo(
      () => onlineBookingCompanyConfig.companyPhoneNumber,
      [onlineBookingCompanyConfig],
    )

    const [unavailableOpen, openUnavailableModal, closeUnavailableModal] =
      useModalState(false)
    const [
      outsideServiceAreaOpen,
      openOutsideServiceAreaModal,
      closeOutsideServiceAreaModal,
    ] = useModalState(false)
    const currentFormStageName = useMemo(
      () => FORM_STAGES[currentStep].name,
      [currentStep],
    )

    const requestDetailsSubForm = useForm<RequestDetailsSubFormData>({
      resolver: zodResolver(RequestDetailsSubFormSchema),
      mode: 'onChange',
      reValidateMode: 'onChange',
    })

    const requestType = requestDetailsSubForm.watch('requestType')
    const jobTypeGuid = requestDetailsSubForm.watch('jobTypeGuid')
    const serviceTypeConfig = useMemo<ServiceTypeConfig | undefined>(
      () => onlineBookingServiceTypeConfigs[requestType],
      [requestType, onlineBookingServiceTypeConfigs],
    )

    const bookableJobTypes = useBookableJobTypes(requestType)
    const selectedJobType = useMemo(
      () => bookableJobTypes.find(j => j.jobTypeGuid === jobTypeGuid),
      [bookableJobTypes, jobTypeGuid],
    )

    const availabilitySubForm = useForm<AvailabilitySubFormData>({
      resolver: zodResolver(AvailabilitySubFormSchema),
      mode: 'onChange',
      reValidateMode: 'onChange',
    })

    const instantBookingSubForm = useForm<InstantBookingSubFormData>({
      resolver: zodResolver(InstantBookingSubFormSchema),
      mode: 'onChange',
      reValidateMode: 'onChange',
    })

    const instantBookingDate = instantBookingSubForm.watch('date')
    const instantBookingArrivalWindowGuid = instantBookingSubForm.watch(
      'companyAppointmentArrivalWindowGuid',
    )

    const selectedArrivalWindow = useMemo(() => {
      return companyAppointmentArrivalWindows.find(
        w =>
          w.companyAppointmentArrivalWindowGuid ===
          instantBookingArrivalWindowGuid,
      )
    }, [companyAppointmentArrivalWindows, instantBookingArrivalWindowGuid])

    const contactSubForm = useForm<ContactSubFormData>({
      resolver: zodResolver(ContactSubFormSchema),
      mode: 'onChange',
      reValidateMode: 'onChange',
    })

    const [submitRequestDetailsElement, triggerRequestDetailsSubmit] =
      useReactHookFormSubmit()
    const [submitAvailabilityElement, triggerAvailabilitySubmit] =
      useReactHookFormSubmit()
    const [submitInstantBookingElement, triggerInstantBookingSubmit] =
      useReactHookFormSubmit()
    const [submitContactElement, triggerContactSubmit] =
      useReactHookFormSubmit()

    const moveToNextStep = useCallback(() => {
      setCurrentStep(currStep => currStep + 1)
    }, [setCurrentStep])

    const moveToPreviousStep = useCallback(() => {
      setCurrentStep(currStep => currStep - 1)
    }, [setCurrentStep])

    const onRequestDetailsSubmit = useCallback(
      (data: RequestDetailsSubFormData) => {
        moveToNextStep()
        // if instant booking, set the date to the earliest available date based
        // on the earliest available date in the service type config
        if (serviceTypeConfig?.bookingType === 'INSTANT') {
          instantBookingSubForm.setValue(
            'date',
            BzDateFns.formatLocalDate(
              getEarliestAvailableDate(
                BzDateFns.getTodayLocalDate(tzId),
                serviceTypeConfig.earliestAvailability ?? '12 hours',
                tzId,
              ),
            ),
          )
        }
      },
      [
        instantBookingSubForm,
        moveToNextStep,
        serviceTypeConfig?.bookingType,
        serviceTypeConfig?.earliestAvailability,
        tzId,
      ],
    )

    const onAvailabilitySubmit = useCallback(
      (data: AvailabilitySubFormData) => {
        moveToNextStep()
      },
      [moveToNextStep],
    )

    const onInstantBookingSubmit = useCallback(
      (data: InstantBookingSubFormData) => {
        moveToNextStep()
      },
      [moveToNextStep],
    )

    const [locationLookupCandidateStatus, setLocationLookupCandidateStatus] =
      useState<'idle' | 'fetching' | 'not-found'>('idle')

    const trpcClient = trpc.useContext().client

    const [locationLookupCandidate, setLocationLookupCandidate] = useState<
      LocationLookupCandidate | undefined
    >(undefined)

    const onContactSubmit = useCallback(
      async (data: ContactSubFormData) => {
        if (data.existingCustomer) {
          setLocationLookupCandidateStatus('fetching')

          try {
            const result = await trpcClient.location[
              'unauth:lookup-location'
            ].query({
              companyGuid,
              addressLine1: data.serviceAddressLine1,
              addressLine2: data.serviceAddressLine2,
              zipCode: data.serviceAddressZipCode,
              stateAbbreviation: data.serviceAddressStateAbbreviation,
            })
            setLocationLookupCandidateStatus(result ? 'idle' : 'not-found')
            setLocationLookupCandidate(result)
            if (result) {
              moveToNextStep()
            } else {
              contactSubForm.setError('root.addressNotFound', {
                message: 'Address not found',
              })
              setLocationLookupCandidateStatus('not-found')
              setLocationLookupCandidate(undefined)
            }
            return true
          } catch (e) {
            console.error(`There was an error fetching the lookup-location`, e)
            setLocationLookupCandidateStatus('not-found')
            setLocationLookupCandidate(undefined)
            contactSubForm.setError('root.addressNotFound', {
              message: 'Address not found',
            })
          }
        } else {
          if (
            serviceAreaZipCodes.length > 0 &&
            !serviceAreaZipCodes.includes(data.serviceAddressZipCode)
          ) {
            console.error(
              `Zip code ${data.serviceAddressZipCode} is not in the service area`,
            )
            openOutsideServiceAreaModal()
            return
          }

          moveToNextStep()
        }
      },
      [
        companyGuid,
        contactSubForm,
        moveToNextStep,
        serviceAreaZipCodes,
        trpcClient.location,
        openOutsideServiceAreaModal,
      ],
    )

    const jobLeadsCreateMutation =
      trpc.jobLeads['unauth:job-leads:create'].useMutation()

    const instantBookingScheduleJobMutation =
      trpc.onlineBooking[
        'unauth:online-booking:instant-booking:schedule-job'
      ].useMutation()

    const submitJobRequest = useCallback(async () => {
      const requestDetailsFormValues = requestDetailsSubForm.getValues()

      const contactFormValues = contactSubForm.getValues()
      const availabilityFormValues = availabilitySubForm.getValues()

      const availabilities: string[] = [
        `${BzDateFns.localDateToFriendlyDateString(
          availabilityFormValues.preferredAvailabilityDate,
        )} (${availabilityFormValues.preferredAvailabilityTimeWindows.join(
          ', ',
        )})`,
      ]

      if (availabilityFormValues.backupAvailabilityDate) {
        if (
          availabilityFormValues.backupAvailabilityTimeWindows &&
          availabilityFormValues.backupAvailabilityTimeWindows.length
        ) {
          availabilities.push(
            `${BzDateFns.localDateToFriendlyDateString(
              availabilityFormValues.backupAvailabilityDate,
            )} (${availabilityFormValues.backupAvailabilityTimeWindows.join(
              ', ',
            )})`,
          )
        } else {
          availabilities.push(
            BzDateFns.localDateToFriendlyDateString(
              availabilityFormValues.backupAvailabilityDate,
            ),
          )
        }
      }

      try {
        if (locationLookupCandidate) {
          await jobLeadsCreateMutation.mutateAsync({
            companyGuid,
            preExistingLocationGuid: locationLookupCandidate.locationGuid,
            requestType: requestDetailsFormValues.requestType,
            submittedJobTypeGuid: requestDetailsFormValues.jobTypeGuid,
            appointmentAvailabilities: availabilities,
            jobSummary: requestDetailsFormValues.requestDetails,
            leadSource: 'Booking Website',
          })
        } else if (contactFormValues.newContact) {
          await jobLeadsCreateMutation.mutateAsync({
            companyGuid,
            // New Contact Information
            contactFirstName: contactFormValues.newContact.firstName,
            contactLastName: contactFormValues.newContact.lastName,
            contactPhoneNumber: contactFormValues.newContact.phoneNumber,
            contactEmailAddress:
              contactFormValues.newContact.primaryEmailAddress,
            serviceAddressLine1: contactFormValues.serviceAddressLine1,
            serviceAddressLine2: contactFormValues.serviceAddressLine2,
            serviceAddressCity: contactFormValues.serviceAddressCity,
            serviceAddressStateAbbreviation:
              contactFormValues.serviceAddressStateAbbreviation,
            serviceAddressZipCode: contactFormValues.serviceAddressZipCode,

            // Request Details
            requestType: requestDetailsFormValues.requestType,
            submittedJobTypeGuid: requestDetailsFormValues.jobTypeGuid,
            appointmentAvailabilities: availabilities,
            jobSummary: requestDetailsFormValues.requestDetails,
            leadSource: 'Booking Website',
          })
        }

        externalOnSubmit('REQUEST')
      } catch (e) {
        console.error(`There was an error creating a job lead`, e)
        message.error(
          `There was an error requesting the job. Please try again in a few minutes.`,
        )
      }
    }, [
      availabilitySubForm,
      companyGuid,
      contactSubForm,
      jobLeadsCreateMutation,
      locationLookupCandidate,
      externalOnSubmit,
      requestDetailsSubForm,
    ])

    const submitInstantBooking = useCallback(async () => {
      const requestDetailsFormValues = requestDetailsSubForm.getValues()

      const contactFormValues = contactSubForm.getValues()
      const instantBookingFormValues = instantBookingSubForm.getValues()

      try {
        if (locationLookupCandidate) {
          const result = await instantBookingScheduleJobMutation.mutateAsync({
            companyGuid,

            // Instant Booking Information
            date: instantBookingFormValues.date,
            companyAppointmentArrivalWindowGuid:
              instantBookingFormValues.companyAppointmentArrivalWindowGuid,
            preExistingLocationGuid: locationLookupCandidate.locationGuid,
            serviceType: requestDetailsFormValues.requestType,
            jobTypeGuid: bzExpect(
              requestDetailsFormValues.jobTypeGuid,
              'Missing job type guid',
            ),
            jobSummary: requestDetailsFormValues.requestDetails,
            leadSource: 'Booking Website',
          })

          if (result.type === 'ERROR') {
            console.error('Arrival window is no longer available', result.error)
            openUnavailableModal()
            return
          }
        } else if (contactFormValues.newContact) {
          const result = await instantBookingScheduleJobMutation.mutateAsync({
            companyGuid,

            // Instant Booking Information
            date: instantBookingFormValues.date,
            companyAppointmentArrivalWindowGuid:
              instantBookingFormValues.companyAppointmentArrivalWindowGuid,

            // New Contact Information
            contactFirstName: contactFormValues.newContact.firstName,
            contactLastName: contactFormValues.newContact.lastName,
            contactPhoneNumber: contactFormValues.newContact.phoneNumber,
            contactEmailAddress:
              contactFormValues.newContact.primaryEmailAddress,
            serviceAddressLine1: contactFormValues.serviceAddressLine1,
            serviceAddressLine2: contactFormValues.serviceAddressLine2,
            serviceAddressCity: contactFormValues.serviceAddressCity,
            serviceAddressStateAbbreviation:
              contactFormValues.serviceAddressStateAbbreviation,
            serviceAddressZipCode: contactFormValues.serviceAddressZipCode,

            // Request Details
            serviceType: requestDetailsFormValues.requestType,
            jobTypeGuid: bzExpect(
              requestDetailsFormValues.jobTypeGuid,
              'Missing job type guid',
            ),
            jobSummary: requestDetailsFormValues.requestDetails,
            leadSource: 'Booking Website',
          })

          if (result.type === 'ERROR') {
            console.error('Arrival window is no longer available', result.error)
            openUnavailableModal()
            return
          }
        }

        externalOnSubmit('INSTANT')
      } catch (e) {
        console.error(`There was an error creating instant booking`, e)
        message.error(
          `There was an error creating instant booking. Please try again in a few minutes.`,
        )
      }
    }, [
      requestDetailsSubForm,
      contactSubForm,
      instantBookingSubForm,
      locationLookupCandidate,
      externalOnSubmit,
      instantBookingScheduleJobMutation,
      companyGuid,
      openUnavailableModal,
    ])

    const onSubmit = useCallback(() => {
      if (serviceTypeConfig?.bookingType === 'INSTANT') {
        submitInstantBooking()
      } else {
        submitJobRequest()
      }
    }, [serviceTypeConfig, submitInstantBooking, submitJobRequest])

    const onTimeWindowUnavailable = useCallback(() => {
      setCurrentStep(1)
      instantBookingSubForm.resetField('companyAppointmentArrivalWindowGuid')
      closeUnavailableModal()
    }, [setCurrentStep, instantBookingSubForm, closeUnavailableModal])

    const isBackButtonEnabled = useMemo(() => {
      return (
        currentStep !== 0 &&
        locationLookupCandidateStatus !== 'fetching' &&
        !jobLeadsCreateMutation.isLoading
      )
    }, [
      currentStep,
      jobLeadsCreateMutation.isLoading,
      locationLookupCandidateStatus,
    ])

    const onPrimaryCTAButtonClicked = useCallback(() => {
      switch (currentFormStageName) {
        case 'Request Details':
          triggerRequestDetailsSubmit()
          break
        case 'Availability':
          if (
            bzExpect(serviceTypeConfig, 'Missing service type config')
              .bookingType === 'REQUEST'
          ) {
            triggerAvailabilitySubmit()
          } else {
            triggerInstantBookingSubmit()
          }

          break
        case 'Contact Info':
          triggerContactSubmit()
          break
        case 'Review Request':
          onSubmit()
          break
        default:
          throw new ThisShouldNeverHappenError('Impossible stage')
      }
    }, [
      currentFormStageName,
      onSubmit,
      serviceTypeConfig,
      triggerAvailabilitySubmit,
      triggerContactSubmit,
      triggerInstantBookingSubmit,
      triggerRequestDetailsSubmit,
    ])

    const onClickCompleteContactForm = useCallback(() => {
      moveToPreviousStep()
      setLocationLookupCandidate(undefined)
      contactSubForm.setValue('existingCustomer', false)
      contactSubForm.setValue('newContact', undefined)
      contactSubForm.setValue('serviceAddressLine1', '')
      contactSubForm.setValue('serviceAddressLine2', '')
      contactSubForm.setValue('serviceAddressCity', '')
      contactSubForm.setValue('serviceAddressStateAbbreviation', '')
      contactSubForm.setValue('serviceAddressZipCode', '')
    }, [moveToPreviousStep, contactSubForm])

    const reviewRequestData = (() => {
      if (currentFormStageName !== 'Review Request') {
        return undefined
      }

      const newContactData = contactSubForm.getValues().newContact
      if (locationLookupCandidate) {
        return {
          name: `${locationLookupCandidate.firstName} ${locationLookupCandidate.lastNameAbbrev}`,
          address: locationLookupCandidate.formattedAddress,
          phoneNumber: locationLookupCandidate.phoneNumberRedacted,
          emailAddress: locationLookupCandidate.emailAddressRedacted,
        }
      } else if (newContactData) {
        const address = BzAddress.create({
          line1: contactSubForm.getValues().serviceAddressLine1,
          line2: contactSubForm.getValues().serviceAddressLine2,
          city: contactSubForm.getValues().serviceAddressCity,
          stateAbbreviation:
            contactSubForm.getValues().serviceAddressStateAbbreviation,
          zipCode: contactSubForm.getValues().serviceAddressZipCode,
        })
        return {
          name: `${newContactData.firstName} ${newContactData.lastName}`,
          address: address.formatSingleLine(),
          phoneNumber: newContactData.phoneNumber,
          emailAddress: newContactData.primaryEmailAddress,
        }
      }

      return undefined
    })()

    const goBack = useCallback(() => {
      setLocationLookupCandidate(undefined)
      contactSubForm.clearErrors()
      moveToPreviousStep()
    }, [contactSubForm, moveToPreviousStep, setLocationLookupCandidate])

    return (
      <>
        <OnsitePageSection padding="large-mobile">
          <div className="flex flex-col">
            <Switch value={currentFormStageName}>
              {{
                'Request Details': () => (
                  <FormStepTitle>Request Details</FormStepTitle>
                ),
                Availability: () => (
                  <>
                    <div className="flex flex-col gap-3">
                      <div className="flex items-center gap-3">
                        <FormStepTitle>Availability</FormStepTitle>
                        {serviceTypeConfig?.bookingType === 'INSTANT' && (
                          <InstantBookingIcon />
                        )}
                      </div>
                      <div>
                        {`Select which date and ${
                          serviceTypeConfig?.bookingType === 'INSTANT'
                            ? 'time'
                            : 'times'
                        } would be best for a technician to come to your home.`}
                      </div>
                    </div>
                    <ThinDivider
                      widthPx={1}
                      styleOverrides={{
                        marginTop: '24px',
                        marginBottom: '24px',
                      }}
                      dividerStyle="dashed"
                    />
                  </>
                ),
                'Contact Info': () => (
                  <FormStepTitle>Contact Info</FormStepTitle>
                ),
                'Review Request': () => (
                  <FormStepTitle>Review Request</FormStepTitle>
                ),
                default: () => null,
              }}
            </Switch>
            {currentFormStageName === 'Request Details' && (
              <FormProvider {...requestDetailsSubForm}>
                <Form
                  requiredMark="optional"
                  className="mt-[12px] flex min-h-0 flex-1 flex-col"
                  layout="vertical"
                  onSubmitCapture={requestDetailsSubForm.handleSubmit(
                    onRequestDetailsSubmit,
                  )}
                >
                  <OnlineBookingRequestDetailsSubForm />
                  {submitRequestDetailsElement}
                </Form>
              </FormProvider>
            )}

            {currentFormStageName === 'Availability' && (
              <Switch value={serviceTypeConfig?.bookingType}>
                {{
                  REQUEST: () => (
                    <FormProvider {...availabilitySubForm}>
                      <Form
                        className="mt-[12px] flex min-h-0 flex-1 flex-col"
                        layout="vertical"
                        onSubmitCapture={availabilitySubForm.handleSubmit(
                          onAvailabilitySubmit,
                        )}
                        requiredMark="optional"
                      >
                        <OnlineBookingAvailabilitySubForm />
                        {submitAvailabilityElement}
                      </Form>
                    </FormProvider>
                  ),
                  INSTANT: () =>
                    serviceTypeConfig && jobTypeGuid && requestType ? (
                      <FormProvider {...instantBookingSubForm}>
                        <Form
                          className="mt-[12px] flex min-h-0 flex-1 flex-col"
                          layout="vertical"
                          onSubmitCapture={instantBookingSubForm.handleSubmit(
                            onInstantBookingSubmit,
                          )}
                          requiredMark="optional"
                        >
                          <OnlineBookingInstantBookingSubForm
                            companyGuid={companyGuid}
                            serviceType={requestType}
                            serviceTypeConfig={serviceTypeConfig}
                            jobTypeGuid={jobTypeGuid}
                          />
                          {submitInstantBookingElement}
                        </Form>
                      </FormProvider>
                    ) : null,
                  default: () => null,
                }}
              </Switch>
            )}

            {currentFormStageName === 'Contact Info' && (
              <FormProvider {...contactSubForm}>
                <Form
                  className="mt-[12px] flex min-h-0 flex-1 flex-col"
                  layout="vertical"
                  onSubmitCapture={contactSubForm.handleSubmit(onContactSubmit)}
                  requiredMark={isMobile ? undefined : 'optional'}
                >
                  <OnlineBookingContactSubForm />
                  {submitContactElement}
                </Form>
              </FormProvider>
            )}

            {currentFormStageName === 'Review Request' && reviewRequestData && (
              <SectionedContent
                dashed
                sections={[
                  {
                    verticalPaddingClassName: 'pt-3 pb-6',
                    content: (
                      <div>
                        <div className="flex flex-col gap-1">
                          {locationLookupCandidate ? (
                            <div>
                              Great, we've found your account information:
                            </div>
                          ) : (
                            <div>
                              Please review your contact information below:
                            </div>
                          )}
                          <div className="flex gap-3">
                            <div>
                              <FontAwesomeIcon
                                size="sm"
                                icon={faUser}
                                className="text-bz-gray-700"
                              />
                            </div>
                            <div className="font-semibold text-[#000000E0]">
                              {reviewRequestData.name}
                            </div>
                          </div>
                          {reviewRequestData.phoneNumber && (
                            <div className="flex gap-3">
                              <div>
                                <FontAwesomeIcon
                                  size="sm"
                                  icon={faPhone}
                                  className="text-bz-gray-700"
                                />
                              </div>
                              <div className="font-semibold text-[#000000E0]">
                                {phoneUtils.tryFormatWithFallback(
                                  reviewRequestData.phoneNumber,
                                  reviewRequestData.phoneNumber,
                                )}
                              </div>
                            </div>
                          )}

                          {reviewRequestData.emailAddress && (
                            <div className="flex gap-3">
                              <div>
                                <FontAwesomeIcon
                                  size="sm"
                                  icon={faEnvelope}
                                  className="text-bz-gray-700"
                                />
                              </div>
                              <div className="font-semibold text-[#000000E0]">
                                {reviewRequestData.emailAddress}
                              </div>
                            </div>
                          )}

                          <div className="flex gap-3">
                            <div>
                              <FontAwesomeIcon
                                size="sm"
                                icon={faLocationDot}
                                className="text-bz-gray-700"
                              />
                            </div>
                            <div className="font-semibold text-[#000000E0]">
                              {reviewRequestData.address}
                            </div>
                          </div>
                        </div>
                        {locationLookupCandidate && (
                          <div className="mt-4">
                            Not you? Complete the{' '}
                            <span
                              className="cursor-pointer text-bz-primary"
                              onClick={onClickCompleteContactForm}
                            >
                              contact form
                            </span>{' '}
                            instead.
                          </div>
                        )}
                      </div>
                    ),
                  },
                  {
                    verticalPaddingClassName: 'pt-6 pb-0',
                    content: (
                      <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-1">
                          <div className="font-semibold text-[#1f1f1f] ">
                            Request Type
                          </div>
                          <div className="text-[#1f1f1f] ">
                            {
                              OnlineBookingServiceTypeDescriptions[
                                requestDetailsSubForm.getValues().requestType
                              ]
                            }
                          </div>
                        </div>
                        {requestDetailsSubForm.getValues().jobTypeGuid &&
                          selectedJobType && (
                            <div className="flex flex-col gap-1">
                              <div className="font-semibold text-[#1f1f1f] ">
                                {`${OnlineBookingServiceTypeDisplayNames[requestType]} Type`}
                              </div>
                              <div className="text-[#1f1f1f] ">
                                {selectedJobType.name}
                              </div>
                            </div>
                          )}
                        {requestDetailsSubForm.getValues().requestDetails && (
                          <div className="flex flex-col gap-1">
                            <div className="font-semibold text-[#1f1f1f]">
                              Request Details
                            </div>
                            <div className="text-[#1f1f1f] ">
                              {requestDetailsSubForm.getValues().requestDetails}
                            </div>
                          </div>
                        )}

                        <Switch value={serviceTypeConfig?.bookingType}>
                          {{
                            INSTANT: () =>
                              selectedArrivalWindow &&
                              instantBookingDate && (
                                <div className="flex flex-col gap-1">
                                  <div className="font-semibold text-[#1f1f1f]">
                                    Date & Arrival Window
                                  </div>
                                  <div className="text-[#1f1f1f]">
                                    {`${BzDateFns.format(
                                      BzDateFns.parseLocalDate(
                                        instantBookingDate,
                                      ),
                                      'EEEE, MMM d,',
                                    )} ${BzDateFns.localTimeStringToFormattedTimeString(
                                      selectedArrivalWindow.arrivalWindowStartTime,
                                      false,
                                    )} - ${BzDateFns.localTimeStringToFormattedTimeString(
                                      selectedArrivalWindow.arrivalWindowEndTime,
                                      false,
                                    )}`}
                                  </div>
                                </div>
                              ),
                            REQUEST: () => (
                              <>
                                {' '}
                                <div className="flex flex-col gap-1">
                                  <div className="font-semibold text-[#1f1f1f] ">
                                    Preferred Date #1
                                  </div>
                                  <div className="text-[#1f1f1f] ">
                                    {BzDateFns.localDateToFriendlyDateString(
                                      availabilitySubForm.getValues()
                                        .preferredAvailabilityDate,
                                    )}{' '}
                                    {'('}
                                    {availabilitySubForm
                                      .getValues()
                                      .preferredAvailabilityTimeWindows.join(
                                        ', ',
                                      )}
                                    {')'}
                                  </div>
                                </div>
                                {availabilitySubForm.getValues()
                                  .backupAvailabilityDate && (
                                  <div className="flex flex-col gap-1">
                                    <div className="font-semibold text-[#1f1f1f] ">
                                      Preferred Date #2
                                    </div>
                                    <div className="text-[#1f1f1f] ">
                                      {BzDateFns.localDateToFriendlyDateString(
                                        availabilitySubForm.getValues()
                                          .backupAvailabilityDate!,
                                      )}
                                      {availabilitySubForm.getValues()
                                        .backupAvailabilityTimeWindows && (
                                        <>
                                          {' '}
                                          {'('}
                                          {availabilitySubForm
                                            .getValues()
                                            .backupAvailabilityTimeWindows!.join(
                                              ', ',
                                            )}
                                          {')'}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </>
                            ),
                            default: () => null,
                          }}
                        </Switch>
                      </div>
                    ),
                  },
                ]}
              />
            )}
          </div>
        </OnsitePageSection>

        <OnsitePageSection padding="large-mobile">
          <div className="flex items-center justify-between">
            <StepProgressIndicators
              currentStep={currentStep}
              totalSteps={FORM_STAGES.length}
              onStepIndicatorClicked={(step: number) => setCurrentStep(step)}
              colorHex={onlineBookingCompanyConfig?.brandingColorHex}
            />
            <div className="flex flex-row gap-[12px]">
              <Button
                disabled={!isBackButtonEnabled}
                onClick={goBack}
                size="large"
              >
                Back
              </Button>
              <Button
                className="min-w-[140px]"
                block
                size="large"
                type="primary"
                style={{
                  backgroundColor: onlineBookingCompanyConfig?.brandingColorHex,
                }}
                onClick={onPrimaryCTAButtonClicked}
                loading={
                  locationLookupCandidateStatus === 'fetching' ||
                  jobLeadsCreateMutation.isLoading ||
                  instantBookingScheduleJobMutation.isLoading
                }
              >
                {currentFormStageName === 'Review Request' ? 'Submit' : 'Next'}
              </Button>
            </div>
          </div>
        </OnsitePageSection>
        {currentFormStageName === 'Review Request' &&
          serviceTypeConfig?.legalBlurbHtml && (
            <OnsitePageSection padding="large-mobile">
              <FormStepTitle>Terms & Conditions</FormStepTitle>
              <TermsAndConditions terms={serviceTypeConfig.legalBlurbHtml} />
            </OnsitePageSection>
          )}
        {currentFormStageName === 'Review Request' &&
          unavailableOpen &&
          selectedArrivalWindow && (
            <TimeWindowUnavailableWindow
              date={instantBookingDate}
              arrivalWindowStart={selectedArrivalWindow.arrivalWindowStartTime}
              arrivalWindowEnd={selectedArrivalWindow.arrivalWindowEndTime}
              onCancel={closeUnavailableModal}
              onConfirm={onTimeWindowUnavailable}
            />
          )}
        {outsideServiceAreaOpen && (
          <OutsideServiceAreaModal
            companyPhoneNumber={companyPhoneNumber}
            onClose={closeOutsideServiceAreaModal}
          />
        )}
      </>
    )
  },
)

const FormStepTitle = React.memo<
  React.PropsWithChildren<{ className?: string }>
>(({ children, className }) => {
  return (
    <div
      className={classNames(
        'break-words text-[20px] font-[600] leading-[28px] text-[rgba(0,0,0,0.88)] text-bz-text',
        className,
      )}
    >
      {children}
    </div>
  )
})

type TermsAndConditionsProps = {
  terms: HtmlString
}

export const TermsAndConditions = React.memo<TermsAndConditionsProps>(
  ({ terms }) => {
    const [blurbExpanded, toggle] = useToggleState(false)

    return (
      <>
        <div
          className={classNames('mt-4', {
            'line-clamp-5': !blurbExpanded,
          })}
        >
          <HtmlRenderer htmlContent={terms} />
        </div>
        <div
          onClick={toggle}
          className={classNames(
            'cursor-pointer underline transition-colors hover:text-bz-gray-700',
          )}
        >
          {blurbExpanded ? 'See less' : 'Read more...'}
        </div>
      </>
    )
  },
)

type TimeWindowUnavailableWindowProps = {
  date: LocalDateString
  arrivalWindowStart: LocalTimeString
  arrivalWindowEnd: LocalTimeString
  onCancel: () => void
  onConfirm: () => void
}

const TimeWindowUnavailableWindow =
  React.memo<TimeWindowUnavailableWindowProps>(
    ({ date, arrivalWindowStart, arrivalWindowEnd, onCancel, onConfirm }) => {
      return (
        <OnsiteConfirmModal
          open
          header="Time unavailable"
          confirmText="Select New Time"
          onCancel={onCancel}
          onConfirm={onConfirm}
        >
          <span>Unfortunately, the</span>
          <span className="font-semibold">
            {` ${BzDateFns.localTimeStringToFormattedTimeString(
              arrivalWindowStart,
              false,
            )} - ${BzDateFns.localTimeStringToFormattedTimeString(
              arrivalWindowEnd,
              false,
            )} `}
          </span>
          <span>arrival window you selected for</span>
          <span className="font-semibold">
            {` ${BzDateFns.format(
              BzDateFns.parseLocalDate(date),
              'MMMM d, yyyy',
            )} `}
          </span>
          <span>
            is no longer available. Please choose a different date or time. We
            apologize for any inconvenience and appreciate your understanding.
          </span>
        </OnsiteConfirmModal>
      )
    },
  )

type OutsideServiceAreaModalProps = {
  companyPhoneNumber: string | undefined
  onClose: () => void
}
const OutsideServiceAreaModal = React.memo<OutsideServiceAreaModalProps>(
  ({ companyPhoneNumber, onClose }) => {
    return (
      <OnsiteBasicModal open header="Outside Service Area" onClose={onClose}>
        It looks like the service address you entered is outside of our current
        service area.
        {companyPhoneNumber && (
          <>
            Please give us a call at{' '}
            <span className="font-semibold">
              {phoneUtils.tryFormat(companyPhoneNumber)}
            </span>{' '}
            so we can discuss how we may still be able to assist you.
          </>
        )}
      </OnsiteBasicModal>
    )
  },
)
