import { times } from '@breezy/shared'
import classNames from 'classnames'
import React, { useCallback, useMemo } from 'react'
import tailwindConfig from '../../tailwind.config'

type StepProgressIndicatorsProps = {
  currentStep: number
  totalSteps: number
  onStepIndicatorClicked: (index: number) => void
  colorHex?: string
}
export const StepProgressIndicators = React.memo<StepProgressIndicatorsProps>(
  ({ currentStep, totalSteps, onStepIndicatorClicked, colorHex }) => {
    const mOnStepIndicatorClicked = useCallback(
      (idx: number) => {
        return () => {
          if (idx < currentStep) {
            onStepIndicatorClicked(idx)
          }
        }
      },
      [currentStep, onStepIndicatorClicked],
    )

    const activeColor = useMemo(() => {
      return colorHex ? colorHex : tailwindConfig.theme.extend.colors.bz.primary
    }, [colorHex])

    return (
      <div className="flex flex-row gap-[4px]">
        {times(totalSteps).map(idx => {
          return (
            <div
              onClick={mOnStepIndicatorClicked(idx)}
              key={idx}
              className={classNames(
                'h-[10px] w-[10px] rounded-full opacity-100 transition-all duration-300 ease-in-out',
                idx <= currentStep
                  ? 'cursor-pointer hover:opacity-80'
                  : 'cursor-disabled cursor-not-allowed',
              )}
              style={{
                backgroundColor:
                  idx <= currentStep
                    ? activeColor
                    : tailwindConfig.theme.extend.colors['bz-gray'][600],
              }}
            />
          )
        })}
      </div>
    )
  },
)
