import { isNullish, R } from '@breezy/shared'
import {
  faEnvelope,
  faGlobe,
  faPhone,
  IconDefinition,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useState } from 'react'
import PersonAvatar from '../PersonAvatar/PersonAvatar'
import { CompanyInfo } from './WithCompanyInfo'

export const COMPANY_INFO_BOX_SIZE_CLASS_NAME = 'w-[400px] min-w-[400px]'

type AccountExperienceCompanyInfoBoxProps = CompanyInfo

type CompanyContactItemProps = {
  icon: IconDefinition
  text: string
  className?: string
}

export const CompanyContactItem = React.memo<CompanyContactItemProps>(
  ({ icon, text, className }) => (
    <div
      className={classNames(
        'flex flex-1 flex-row items-center gap-3 text-sm font-semibold leading-[22px]',
        className,
      )}
    >
      <FontAwesomeIcon icon={icon} className="h-5 w-5 text-bz-gray-500" />
      <div className="text-bz-gray-900">{text}</div>
    </div>
  ),
)

type CompanyLogoProps = {
  logoUrl: string
  companyName: string
  className?: string
}

export const CompanyLogo = React.memo<CompanyLogoProps>(
  ({ logoUrl, companyName, className }) => (
    <img
      className={classNames(
        'h-[140px] w-auto max-w-full object-contain object-left',
        className,
      )}
      src={logoUrl}
      alt={companyName}
    />
  ),
)

type CompanyBlurbProps = {
  companyBlurb: string
  blurbExpanded: boolean
  onToggleBlurbExpanded: () => void
}

export const CompanyBlurb = React.memo<CompanyBlurbProps>(
  ({ companyBlurb, blurbExpanded, onToggleBlurbExpanded }) => (
    <>
      <div
        className={classNames('mt-4', {
          'line-clamp-5': !blurbExpanded,
        })}
      >
        {companyBlurb}
      </div>
      <div
        onClick={onToggleBlurbExpanded}
        className={classNames(
          'cursor-pointer underline transition-colors hover:text-bz-gray-700',
        )}
      >
        {blurbExpanded ? 'See less' : 'Read more...'}
      </div>
    </>
  ),
)

export const AccountExperienceCompanyInfoBox =
  React.memo<AccountExperienceCompanyInfoBoxProps>(
    ({
      techName,
      logoUrl,
      companyName,
      companyBlurb,
      companyPhoneNumber,
      companyEmail,
      companyWebsite,
      companyWebsiteDisplayText,
      techAvatarImageUrl,
    }) => {
      const [blurbExpanded, setBlurbExpanded] = useState(false)

      return (
        // I want this box here for layout purposes. However, I want the actual box to be fixed at the top if the right side
        // scrolls. What's cool is doing `fixed` without a `top` or a `left` will put it where it would otherwise go, it
        // just doesn't move.
        <div className={classNames(COMPANY_INFO_BOX_SIZE_CLASS_NAME, 'mr-10')}>
          <div
            className={classNames(
              'review-estimate-company-info-drop-shadow fixed rounded-2xl px-6 py-4',
              COMPANY_INFO_BOX_SIZE_CLASS_NAME,
            )}
          >
            <CompanyLogo logoUrl={logoUrl} companyName={companyName} />
            <div className="mt-4 text-3xl font-semibold">{companyName}</div>
            <CompanyBlurb
              companyBlurb={companyBlurb}
              blurbExpanded={blurbExpanded}
              onToggleBlurbExpanded={() => setBlurbExpanded(R.not)}
            />
            {techName && (
              <div className="mt-4 border-0 border-t border-solid border-t-bz-gray-400 pt-4 text-base">
                <div className="flex flex-row items-center gap-3">
                  {!isNullish(techAvatarImageUrl) && (
                    <PersonAvatar
                      userGuid=""
                      avatarAltShortString=""
                      avatarImgSrc={techAvatarImageUrl}
                      size={64}
                    />
                  )}

                  <div className="flex flex-col gap-0">
                    <div className="font-semibold">{techName}</div>
                    <div className="text-bz-gray-900">Technician</div>
                  </div>
                </div>
              </div>
            )}
            {(companyPhoneNumber || companyEmail || companyWebsite) && (
              <>
                <div className="mb-4 mt-4 border-0 border-t border-dashed border-t-bz-border" />
                <div className="flex flex-col gap-2">
                  {companyPhoneNumber && (
                    <CompanyContactItem
                      icon={faPhone}
                      text={companyPhoneNumber}
                    />
                  )}
                  {companyEmail && (
                    <CompanyContactItem icon={faEnvelope} text={companyEmail} />
                  )}
                  {companyWebsite && (
                    <CompanyContactItem
                      icon={faGlobe}
                      text={companyWebsiteDisplayText || companyWebsite}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )
    },
  )
