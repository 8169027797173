import { CompanyGuid, UserGuid } from '@breezy/shared'
import { useMemo } from 'react'
import { useQuery } from 'urql'
import { DocumentType } from '../../generated'
import { FETCH_COMPANY_INFO_BOX_DATA } from './AccountExperienceCompanyInfoBox.gql'
import { CompanyInfo } from './WithCompanyInfo'

type CompanyInfoQuery = DocumentType<typeof FETCH_COMPANY_INFO_BOX_DATA>

export const convertQueryToCompanyInfo = (
  data: CompanyInfoQuery,
): CompanyInfo => {
  return {
    logoUrl: data?.companies[0]?.billingProfile?.logoUrl ?? '',
    companyName: data?.companies[0]?.name ?? '',
    companyBlurb: data?.companies[0]?.companyConfig?.blurb ?? '',
    techName:
      data?.companies[0]?.companyUsers[0]?.userByUserGuid?.fullName ?? '',
    companyPhoneNumber:
      data?.companies[0]?.billingProfile?.phoneNumber.phoneNumber,
    companyEmail: data?.companies[0]?.billingProfile?.emailAddress.emailAddress,
    companyWebsite: data?.companies[0]?.billingProfile?.websiteUrl,
    companyWebsiteDisplayText:
      data?.companies[0]?.billingProfile?.websiteDisplayName,
    techAvatarImageUrl:
      data.companies[0].companyUsers[0]?.userByUserGuid?.avatarPhoto?.cdnUrl,
  }
}

export const useFetchCompanyInfo = (
  companyGuid: CompanyGuid,
  userGuid?: UserGuid,
) => {
  const companyInfoQuery = useQuery({
    query: FETCH_COMPANY_INFO_BOX_DATA,
    variables: {
      companyGuid,
      companyUsersWhere: userGuid
        ? {
            userGuid: { _eq: userGuid },
          }
        : undefined,
    },
  })

  const [{ data, fetching }] = companyInfoQuery

  const companyInfo = useMemo(() => {
    return {
      logoUrl: data?.companies[0]?.billingProfile?.logoUrl ?? '',
      companyName: data?.companies[0]?.name ?? '',
      companyBlurb: data?.companies[0]?.companyConfig?.blurb ?? '',
      techName:
        data?.companies[0]?.companyUsers[0]?.userByUserGuid?.fullName ?? '',
      companyPhoneNumber:
        data?.companies[0]?.billingProfile?.phoneNumber.phoneNumber,
      companyEmail:
        data?.companies[0]?.billingProfile?.emailAddress.emailAddress,
      companyWebsite: data?.companies[0]?.billingProfile?.websiteUrl,
      companyWebsiteDisplayText:
        data?.companies[0]?.billingProfile?.websiteDisplayName,
    }
  }, [data])

  return { data, fetching, companyInfoQuery, companyInfo }
}
