import { CompanyGuid, UserGuid } from '@breezy/shared'
import React from 'react'
import GqlQueryLoader from '../GqlQueryLoader/GqlQueryLoader'
import {
  convertQueryToCompanyInfo,
  useFetchCompanyInfo,
} from './useFetchCompanyInfo'

export type CompanyInfo = {
  logoUrl: string
  companyName: string
  companyBlurb: string
  techName?: string
  companyPhoneNumber?: string
  companyEmail?: string
  companyWebsite?: string
  companyWebsiteDisplayText?: string
  techAvatarImageUrl?: string
}

type WithCompanyInfoProps = {
  companyGuid: CompanyGuid
  userGuid?: UserGuid
  loadingComponent?: JSX.Element
  render: (companyInfo: CompanyInfo) => JSX.Element
}

export const WithCompanyInfo = React.memo<WithCompanyInfoProps>(
  ({ companyGuid, userGuid, render, loadingComponent }) => {
    const { companyInfoQuery } = useFetchCompanyInfo(companyGuid, userGuid)

    return (
      <GqlQueryLoader
        query={companyInfoQuery}
        loadingComponent={loadingComponent}
        idleComponent={loadingComponent}
        render={data => {
          return render(convertQueryToCompanyInfo(data))
        }}
      />
    )
  },
)
