import {
  Address,
  BzAddress,
  BzDateFns,
  DiscountType,
  formatMoney,
  formatPercentage,
  invoiceTermDisplayNames,
  usdMultiply,
  usdToUsCents,
} from '@breezy/shared'
import { faDownload, faPrint } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider } from 'antd'
import classNames from 'classnames'
import React, { useContext, useMemo } from 'react'
import LoanPromoDisclaimer from '../../components/Financing/LoanPromo/LoanPromoDisclaimer'
import LoanPromo from '../../components/Financing/LoanPromo/LoanPromoView'
import { PrequalifiedBannerInfo } from '../../components/PrequalifiedBannerInfo/PrequalifiedBannerInfo'
import { HtmlRenderer } from '../../elements/HtmlRenderer/HtmlRenderer'
import useIsMobile, { useIsSmallScreen } from '../../hooks/useIsMobile'
import {
  useDownloadInvoice,
  useFetchPdfBlobUnauth,
  useOpenInvoiceInNewTab,
} from '../../utils/invoice-utils'
import { useEmbeddedLoanApplication } from './hooks/useEmbeddedLoanApplication'
import { SelfServePaymentContext } from './selfServePaymentUtils'

const BOTTOM_BORDER_CLASS_NAME =
  'border-0 border-b border-solid border-bz-gray-400'

type InvoiceSectionProps = React.PropsWithChildren<{
  title?: string
  className?: string
}>

const InvoiceSection = React.memo<InvoiceSectionProps>(
  ({ title, children, className }) => {
    return (
      <div
        className={classNames('my-4 pb-4', BOTTOM_BORDER_CLASS_NAME, className)}
      >
        {title && <div className="mb-2 text-base font-semibold">{title}</div>}
        {children}
      </div>
    )
  },
)

type GridCellProps = React.PropsWithChildren<{
  className?: string
  compact?: boolean
}>

const GridCell = React.memo<GridCellProps>(
  ({ className, children, compact }) => (
    <div
      className={classNames(
        className,
        'even:pl-4 even:text-right',
        compact ? 'pb-1' : 'mb-3 pb-3',
        {
          [BOTTOM_BORDER_CLASS_NAME]: !compact,
        },
      )}
    >
      {children}
    </div>
  ),
)

type SubtotalLineProps = React.PropsWithChildren<{ label: string }>

const SubtotalLine = React.memo<SubtotalLineProps>(({ label, children }) => {
  return (
    <>
      <GridCell compact className="text-bz-gray-700">
        {label}
      </GridCell>
      <GridCell compact className="text-bz-gray-900">
        {children}
      </GridCell>
    </>
  )
})

const addressToLines = (address: Address): string[] => {
  const lines = [address.line1]
  if (address.line2) {
    lines.push(address.line2)
  }
  return [
    ...lines,
    `${address.city}, ${address.stateAbbreviation}`,
    address.zipCode,
  ]
}

export const SelfServePaymentInvoice = React.memo(() => {
  const {
    companyGuid,
    invoice,
    logoUrl,
    contractorLicenseNumber,
    wisetackEnabled,
  } = useContext(SelfServePaymentContext)

  const isSmallScreen = useIsSmallScreen()
  const isMobile = useIsMobile()

  const completedAt = useMemo(() => {
    if (!invoice.serviceCompletionDate) {
      return ''
    }
    const date = BzDateFns.parseLocalDate(invoice.serviceCompletionDate)
    return BzDateFns.format(date, 'MM/dd/yyyy')
  }, [invoice.serviceCompletionDate])

  const className = useMemo(() => {
    const classes: string[] = []
    if (isSmallScreen) {
      // Accounts for the floating button
      classes.push('mb-[68px]')
      if (isMobile) {
        classes.push('p-4')
      } else {
        classes.push('p-16')
      }
    } else {
      classes.push('mt-14 w-[512px] max-w-[512px]')
    }
    classes.push('mb-8')
    return classes.join(' ')
  }, [isMobile, isSmallScreen])

  const addressDisplayData = useMemo(
    () =>
      [
        [
          'Service Address',
          `${invoice.jobLocation.contact.firstName} ${invoice.jobLocation.contact.lastName}`,
          addressToLines(invoice.jobLocation.address),
        ],
        [
          'Billing Address',
          `${invoice.billingInfo.billingContact.firstName} ${invoice.billingInfo.billingContact.lastName}`,
          BzAddress.makeCanonicalFullAddress(
            invoice.billingInfo.payeeAddress,
          ) === BzAddress.makeCanonicalFullAddress(invoice.jobLocation.address)
            ? ['(Same as service address)']
            : addressToLines(invoice.billingInfo.payeeAddress),
        ],
      ] as const,
    [
      invoice.billingInfo.billingContact.firstName,
      invoice.billingInfo.billingContact.lastName,
      invoice.billingInfo.payeeAddress,
      invoice.jobLocation.address,
      invoice.jobLocation.contact.firstName,
      invoice.jobLocation.contact.lastName,
    ],
  )

  const paymentCallToAction = useMemo(() => {
    return formatMoney(invoice.paymentsSummary.amountDueUsd)
  }, [invoice.paymentsSummary.amountDueUsd])

  const fetchUnathInvoiceBlob = useFetchPdfBlobUnauth(companyGuid)
  const { downloadInvoicePDF, invoiceDownloading } = useDownloadInvoice(
    companyGuid,
    invoice.invoiceGuid,
    invoice.displayId.toString(),
    fetchUnathInvoiceBlob,
  )
  const { beginOpenInvoice, invoiceGenerating } = useOpenInvoiceInNewTab(
    invoice.invoiceGuid,
    fetchUnathInvoiceBlob,
  )

  return (
    <div
      className={classNames(
        'relative flex flex-1 items-start justify-center overflow-auto bg-white',
        { 'px-6': !isSmallScreen },
      )}
    >
      {contractorLicenseNumber && (
        <div className="absolute right-2 top-2 text-xs font-medium text-[grey]">
          CL # {contractorLicenseNumber}
        </div>
      )}
      <div className={className}>
        <div className="flex w-full flex-row gap-x-2">
          <Button onClick={downloadInvoicePDF} loading={invoiceDownloading}>
            <FontAwesomeIcon icon={faDownload} className="mr-4" />
            Download
          </Button>
          <Button onClick={beginOpenInvoice} loading={invoiceGenerating}>
            <FontAwesomeIcon icon={faPrint} className="mr-4" />
            Print
          </Button>
        </div>
        <Divider />
        <div className={classNames('mb-4 flex pb-4', BOTTOM_BORDER_CLASS_NAME)}>
          <div className="flex-1">
            <div className="mb-1 text-base font-semibold">
              Invoice #{invoice.displayId}
            </div>
            {completedAt && (
              <div className="text-bz-gray-700">
                Service Completed: {completedAt}
              </div>
            )}
            <div className="text-bz-gray-700">
              Term: {invoiceTermDisplayNames[invoice.invoiceTerm]}
            </div>
          </div>
          <img
            src={logoUrl}
            alt="logo"
            className="ml-1 h-[72px] w-[174px] object-contain object-right-top"
          />
        </div>
        <div>
          <InvoiceSection
            className={classNames(
              'flew-row flex items-start',
              isMobile ? 'justify-between' : 'justify-start space-x-8',
            )}
          >
            {addressDisplayData.map(([label, name, addressLines]) => (
              <div key={label} className="leading-6">
                <div className="mb-2 text-base font-semibold">{label}</div>
                <div className="font-semibold">{name}</div>
                {addressLines.map(line => (
                  <div key={line}>{line}</div>
                ))}
              </div>
            ))}
          </InvoiceSection>
          <div
            className={classNames(
              'my-4 pb-4 text-3xl font-semibold',
              BOTTOM_BORDER_CLASS_NAME,
            )}
          >
            {`Due: ${paymentCallToAction}`}
          </div>
          {invoice.summary && (
            <InvoiceSection title="Summary">
              <div className="text-bz-gray-900">
                <HtmlRenderer htmlContent={invoice.summary} />
              </div>
            </InvoiceSection>
          )}
          {invoice.jobCustomerPurchaseOrderNumber && (
            <InvoiceSection title="PO Number">
              <div className="text-bz-gray-900">
                {invoice.jobCustomerPurchaseOrderNumber}
              </div>
            </InvoiceSection>
          )}
          <div className="mb-3 text-base font-semibold">Line Items</div>
          <div className="grid grid-cols-[1fr_auto]">
            {(['Description', 'Total'] as const).map(label => (
              <GridCell
                key={label}
                className="little-caps font-semibold text-bz-gray-800"
              >
                {label}
              </GridCell>
            ))}

            {invoice.items.map(item => {
              const totalUsd = usdMultiply(item.unitPriceUsd, item.quantity)
              const description =
                item.description === item.name ? '' : item.description
              return (
                <React.Fragment key={item.itemGuid}>
                  <GridCell className="px-4">
                    <div className="mb-1 font-semibold">{item.name}</div>
                    {description && (
                      <div>
                        <HtmlRenderer htmlContent={description} />
                      </div>
                    )}
                  </GridCell>
                  <GridCell
                    className={classNames(
                      'flex',
                      description ? 'flex-col items-end' : 'flex-row-reverse',
                    )}
                  >
                    <div className="pl-2">{formatMoney(totalUsd)}</div>
                    {item.quantity > 1 && (
                      <div className="whitespace-nowrap text-bz-gray-700">
                        ({item.quantity} x {formatMoney(item.unitPriceUsd)})
                      </div>
                    )}
                  </GridCell>
                </React.Fragment>
              )
            })}

            {invoice.discountsV2.map(discount => {
              const description =
                discount.description === discount.displayName
                  ? ''
                  : discount.description
              return (
                <React.Fragment key={discount.discountGuid}>
                  <GridCell className="px-4 text-bz-green-800">
                    <div className="mb-1 font-semibold">
                      {discount.displayName}
                    </div>
                    {description && (
                      <div>
                        <HtmlRenderer htmlContent={description} />
                      </div>
                    )}
                  </GridCell>
                  <GridCell
                    className={classNames(
                      'flex text-bz-green-800',
                      description ? 'flex-col items-end' : 'flex-row-reverse',
                    )}
                  >
                    <div className="pl-2">
                      -
                      {formatMoney(
                        discount.type === DiscountType.FLAT
                          ? discount.discountAmountUsd
                          : // If it's not flat, it's a rate. If it's a rate, we know it's the only one. If it's the only
                            // discount, the full discount amount is attributable to this discount.
                            invoice.discountAmountUsd,
                      )}
                    </div>
                    {discount.type === DiscountType.RATE && (
                      <div className="whitespace-nowrap">
                        ({formatPercentage(discount.discountRate)})
                      </div>
                    )}
                  </GridCell>
                </React.Fragment>
              )
            })}

            <SubtotalLine label="Subtotal">
              {formatMoney(invoice.subtotalPriceUsd)}
            </SubtotalLine>
            {invoice.discountAmountUsd > 0 && (
              <SubtotalLine label="Discount">
                -{formatMoney(invoice.discountAmountUsd)}
              </SubtotalLine>
            )}
            <SubtotalLine label={`Tax (${invoice.taxRate.rate * 100}%)`}>
              {formatMoney(invoice.taxAmountUsd)}
            </SubtotalLine>
            <GridCell compact className="text-base font-semibold">
              Grand Total
            </GridCell>
            <GridCell compact className="text-base font-semibold">
              {formatMoney(invoice.paymentsSummary.amountDueUsd)}
            </GridCell>
            {invoice.paymentsSummary.totalPaidAmountUsd > 0 && (
              <SubtotalLine label="Previous Payment(s)">
                {formatMoney(invoice.paymentsSummary.totalPaidAmountUsd)}
              </SubtotalLine>
            )}
          </div>
          {wisetackEnabled && <InvoiceWisetackPromo />}
        </div>
      </div>
    </div>
  )
})

const InvoiceWisetackPromo = React.memo(() => {
  const { invoice, loanApplicationStarted, companyName } = useContext(
    SelfServePaymentContext,
  )
  const { embeddedLoanApplication, toggle } = useEmbeddedLoanApplication()

  return (
    <div>
      <LoanPromo
        amountUsc={usdToUsCents(invoice.totalPriceUsd)}
        onQualify={toggle}
        qualifyText={loanApplicationStarted ? 'Continue' : 'Qualify Now'}
        showLearnMore
      />
      <div className="mt-3">
        <PrequalifiedBannerInfo
          accountGuid={invoice.accountGuid}
          companyName={companyName}
        />
      </div>
      <LoanPromoDisclaimer amountUsc={usdToUsCents(invoice.totalPriceUsd)} />
      {embeddedLoanApplication}
    </div>
  )
})
