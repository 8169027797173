import { anonGql, DocumentType } from '../../../generated'

export const ANON_REVIEW_INVOICE = anonGql(/* GraphQL */ `
  subscription ReviewInvoice($invoiceGuid: uuid!) {
    invoicesByPk(invoiceGuid: $invoiceGuid) {
      invoiceGuid
      companyGuid
      dueAt
      customerPurchaseOrderNumber
      messageHtml
      status
      serviceCompletionDate
      taxRate
      taxRateName
      taxRateGuid
      dynamicPricingType
      displayId
      displayIdV2
      wisetackLoanRecords(orderBy: { createdAt: DESC }) {
        companyGuid
        wisetackLoanRecordGuid
        wisetackLoanStatuses(
          limit: 1
          orderBy: { occurredAt: DESC, createdAt: DESC }
        ) {
          loanStatus
          createdAt
        }
        createdAt
        updatedAt
        loanApplicationLink
        approvedLoanAmountUsc
        requestedLoanAmountUsc
      }
      invoiceCartItems(orderBy: { seq: ASC }) {
        seq
        cartItem {
          cartItemGuid
          description
          isDiscountable
          isTaxable
          name
          quantity
          unitPriceUsc
          photo {
            cdnUrl
            photoGuid
          }
        }
      }
      invoiceDiscounts(orderBy: { seq: ASC }) {
        discountRate
        discountAmountUsc
        descriptionHtml
        discountType
        invoiceDiscountGuid
        invoiceGuid
        name
      }
      billingAddress {
        line1
        line2
        city
        addressGuid
        zipCode
        stateAbbreviation
      }
      billingContact {
        contactGuid
        firstName
        fullName
        lastName
        primaryEmailAddress {
          emailAddress
        }
        primaryPhoneNumber {
          phoneNumber
        }
      }
      jobLink {
        jobGuid
        job {
          pointOfContact {
            fullName
            firstName
            contactGuid
            primaryEmailAddress {
              emailAddress
            }
            primaryPhoneNumber {
              phoneNumber
            }
          }
        }
      }
      maintenancePlanLink {
        maintenancePlanGuid
      }
      locationLink {
        locationGuid
      }
      issuedAt
      billingContactGuid
      serviceAddress {
        zipCode
        city
        line1
        stateAbbreviation
      }
      invoiceTerm
      createdAt
      accountGuid
      account {
        accountContacts(orderBy: { primary: DESC }) {
          contact {
            fullName
            firstName
            primaryEmailAddress {
              emailAddress
            }
            primaryPhoneNumber {
              phoneNumber
            }
            lastName
            contactGuid
          }
        }
      }
      paymentRecords(
        where: {
          paymentStatuses: { paymentStatus: { _nin: [CANCELED, FAILED] } }
        }
      ) {
        paymentRecordGuid
        paymentMethod
        occurredAt
        paymentRefunds {
          amountUsd
          occurredAt
        }
        amountUsd
        paymentStatuses(orderBy: { occurredAt: DESC }, limit: 1) {
          paymentStatus
        }
      }
    }
  }
`)

export type ReviewInvoice = NonNullable<
  DocumentType<typeof ANON_REVIEW_INVOICE>['invoicesByPk']
>

export type SimpleLoanRecord = ReviewInvoice['wisetackLoanRecords'][0]

export const ANON_RELEVANT_INVOICE_DATA_SUBSCRIPTION = anonGql(/* GraphQL */ `
  subscription GetRelevantReviewInvoiceData($companyGuid: uuid!) {
    companiesByPk(companyGuid: $companyGuid) {
      companyName: name
      tzId: timezone
      companyConfig {
        companyBlurb: blurb
      }
      billingProfile {
        businessFullLegalName
        contractorLicenseNumber
        logoUrl
        invoiceDisclaimer
        businessAddress {
          city
          line1
          line2
          zipCode
          stateAbbreviation
        }
        businessWebsite: websiteDisplayName
        emailAddress {
          emailAddress
        }
        phoneNumber {
          phoneNumber
        }
        wisetackMerchantId
        tilledMerchantAccountId
      }
    }
  }
`)

export type RelevantReviewInvoiceData = NonNullable<
  DocumentType<typeof ANON_RELEVANT_INVOICE_DATA_SUBSCRIPTION>['companiesByPk']
>
