import { gql } from '../../generated'

export const FETCH_COMPANY_INFO_BOX_DATA = gql(/* GraphQL */ `
  query FetchCompanyInfoBoxData(
    $companyGuid: uuid!
    $companyUsersWhere: CompanyUsersBoolExp = { userGuid: { _isNull: true } }
  ) {
    companies(where: { companyGuid: { _eq: $companyGuid } }) {
      name
      companyConfig {
        blurb
      }
      billingProfile {
        logoUrl
        phoneNumber {
          phoneNumber
        }
        websiteUrl
        websiteDisplayName
        emailAddress {
          emailAddress
        }
      }
      companyUsers(where: $companyUsersWhere) {
        userByUserGuid {
          userGuid
          fullName
          avatarPhoto {
            cdnUrl
          }
        }
      }
    }
  }
`)
